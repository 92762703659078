import React from 'react'

export default function BigNumeros({ detalhes }) {

    return (
        <>
            <div>
                <h1>{detalhes.titulo}</h1><br/>
                <p>{detalhes.descricao}</p>
            </div>
        </>
    )
}
