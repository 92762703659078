import styled from "styled-components";

export const Container = styled.form`
display: flex;
flex-direction: row;
margin: auto;
background-color: #5a759752;
`;

export const ContainerSidebar = styled.form`
display: flex;
flex-direction: column;
margin: auto;

`;

export const ContainerStage= styled.form`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
margin-left: 15px;

`;